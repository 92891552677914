import React from "react";
import BackgroundCanvas from "../BackgroundCanvas";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";

const Main: React.FC = () => {
  return (
    <div className="App">
      <div className="main">
        <BackgroundCanvas>
          <header className="page-header visible-always">
            <span className="first-letter">A</span>
            <span className="the-n blinker">n</span>
          </header>

          <header className="page-header">
            <span className="first-letter">A</span>
            <span>nna Kopp</span>
          </header>
          <div className="description">
            <div>
              Product driven software engineer based in NYC.
              <p>
                Previously: <br />
                <ul>
                  <li>Founder and Chief Product Officer at Co-Star</li>
                  <li>Tech lead at VFILES</li>
                </ul>
              </p>
              <p>
                I like strongly typed languages and user focused product
                development.{" "}
              </p>
            </div>
          </div>
        </BackgroundCanvas>
      </div>
      <div className="footer">
        <ReactGA.OutboundLink
          eventLabel="gitlab"
          to="https://gitlab.com/annakopp"
          target="_blank"
        >
          GITLAB
        </ReactGA.OutboundLink>{" "}
        |{" "}
        <ReactGA.OutboundLink
          eventLabel="github"
          to="https://github.com/annakopp"
          target="_blank"
        >
          GITHUB
        </ReactGA.OutboundLink>{" "}
        |{" "}
        <ReactGA.OutboundLink
          eventLabel="linkedin"
          to="https://www.linkedin.com/in/annakopp/"
          target="_blank"
        >
          LINKEDIN
        </ReactGA.OutboundLink>{" "}
        |{" "}
        <ReactGA.OutboundLink
          eventLabel="tic tac toe"
          to="https://annakopp.com/tictactoe/"
          target="_blank"
        >
          {" "}
          GAME
        </ReactGA.OutboundLink>{" "}
        | <Link to="/whichwordle">WHICH WORDLE</Link>
      </div>
    </div>
  );
};

export default Main;
