import * as React from "react";
import { XIcon } from "@heroicons/react/outline";

const Modal: React.FC<{ isOpen: boolean; onClose: VoidFunction }> = ({
  children,
  isOpen,
  onClose,
}) => {
  return (
    <div
      className={`${
        isOpen ? "block" : "hidden"
      } fixed inset-0 flex justify-center items-center`}
    >
      <div className="max-w-lg max-h-[90vh] border border-egray bg-eblue relative">
        <XIcon
          className="h-6 w-h cursor-pointer right-2 top-2 absolute"
          onClick={onClose}
        />
        <div className="overflow-scroll max-h-[inherit] p-4 pt-10">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
