import * as React from "react";
import Keyboard from "../components/WhichWordle/Keyboard";
import Modal from "../components/WhichWordle/Modal";
import { DateTime } from "luxon";
import {
  QuestionMarkCircleIcon,
  ChartSquareBarIcon,
} from "@heroicons/react/outline";

interface Wordle {
  name: string;
  url: string;
}

const wordles: Wordle[] = [
  { name: "heardle", url: "https://heardle.app" },
  { name: "wordle", url: "https://www.nytimes.com/games/wordle/index.html" },
  { name: "worldle", url: "https://worldle.teuteuf.fr/" },
  { name: "quordle", url: "https://www.quordle.com/" },
  { name: "octordle", url: "https://octordle.com/?mode=daily" },
  { name: "semantle", url: "https://semantle.novalis.org/" },
  { name: "absurdle", url: "https://qntm.org/files/absurdle/absurdle.html" },
  { name: "nerdle", url: "https://nerdlegame.com/" },
  { name: "squabble", url: "https://squabble.me/" },
  { name: "globle", url: "https://globle-game.com/" },
  //mastermind?
];

//suggest another
// info, history

interface TodaysResult {
  keyPressed: string;
  wordleSelected: Wordle;
}

const getStorage = (currentDate: string): TodaysResult | null => {
  const todaysResultString = localStorage.getItem(`wordle-${currentDate}`);
  return todaysResultString !== null ? JSON.parse(todaysResultString) : null;
};

const WordleLink: React.FC<{ wordle?: Wordle; className?: string }> = ({
  wordle,
  className,
}) => {
  return (
    <a
      href={wordle?.url}
      target="_blank"
      rel="noreferrer"
      className={`${className} underline font-bold text-egray`}
    >
      {wordle?.name}
    </a>
  );
};

const Info = () => {
  return (
    <div>
      <p>Overwhelmed with too many wordles to do? I'll pick for you.</p>
      <p className="pt-10">Possible Options</p>
      <ul className="list-disc list-inside">
        {wordles.map((w) => (
          <li key={w.name}>
            <WordleLink wordle={w} className="text-xs" />
          </li>
        ))}
      </ul>
    </div>
  );
};

type Calendar = { date: DateTime; todaysResult: TodaysResult | null }[];

const LilCal = () => {
  const firstWeekday = DateTime.now().startOf("month").weekday;
  const spacerDays = Array.from({ length: firstWeekday }, (v, k) => k + 1);
  const lastDateOfMonth = DateTime.now().endOf("month");
  const lastDayOfMonth = lastDateOfMonth.day;
  const datesInMonth = Array.from(
    { length: lastDayOfMonth },
    (v, k) => k + 1
  ).map((day) =>
    DateTime.fromObject({
      month: lastDateOfMonth.month,
      day,
      year: lastDateOfMonth.year,
    })
  );
  const calendar: Calendar = datesInMonth.map((date) => {
    const todaysResult = getStorage(date.toISODate());
    return { date, todaysResult };
  });
  return (
    <div className="flex flex-col items-center">
      <h2>{lastDateOfMonth.monthLong}</h2>
      <div className="grid grid-cols-7 pt-4">
        {spacerDays.map((_, idx) => (
          <div className="w-12 h-12 p-1" key={idx}></div>
        ))}
        {calendar.map((c) => (
          <div
            className="flex flex-col w-12 h-12 p-1 border border-[0.5px] text-xs"
            key={c.date.day}
          >
            <div className="leading-none">{c.date.day}</div>
            <div className="text-sm font-bold text-center underline">
              <a href={c.todaysResult?.wordleSelected.url}>
                {c.todaysResult?.wordleSelected.name.charAt(0)}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const History = () => {
  return (
    <div>
      <LilCal />
    </div>
  );
};

const Header = () => {
  const [infoModalOpen, setInfoModalOpen] = React.useState(false);
  const [historyModalOpen, setHistoryModalOpen] = React.useState(false);
  return (
    <header className="flex space-x-2 items-center pt-2">
      <QuestionMarkCircleIcon
        className="h-6 w-6 cursor-pointer"
        onClick={() => setInfoModalOpen(true)}
      />
      <Modal isOpen={infoModalOpen} onClose={() => setInfoModalOpen(false)}>
        <Info />
      </Modal>
      <h1>Which Wordle</h1>
      <ChartSquareBarIcon
        className="h-6 w-6 cursor-pointer"
        onClick={() => setHistoryModalOpen(true)}
      />
      <Modal
        isOpen={historyModalOpen}
        onClose={() => setHistoryModalOpen(false)}
      >
        <History />
      </Modal>
    </header>
  );
};

const WhichWordle = () => {
  const currentDate = DateTime.now().toISODate();
  const [todaysResult, setTodaysResult] = React.useState(
    getStorage(currentDate)
  );
  const [showResult, setShowResult] = React.useState(todaysResult !== null);
  const [showWarning, setShowWarning] = React.useState(false);
  const saveRandomResult = React.useCallback(
    (key: string) => {
      if (todaysResult === null) {
        const randomWordle =
          wordles[Math.floor(Math.random() * wordles.length)];
        const newResult: TodaysResult = {
          keyPressed: key,
          wordleSelected: randomWordle,
        };
        const stringResult = JSON.stringify(newResult);
        localStorage.setItem(`wordle-${currentDate}`, stringResult);
        setTodaysResult(newResult);
      } else {
        setShowWarning(true);
      }
    },
    [todaysResult, currentDate]
  );

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (showWarning) {
        setShowWarning(false);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [showWarning]);

  const handleUserKeyPress = React.useCallback(
    (event: KeyboardEvent) => {
      const { key } = event;
      saveRandomResult(key);
    },
    [saveRandomResult]
  );

  React.useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);
  return (
    <div className="bg-eblue">
      <div className="flex flex-col items-center max-w-md m-auto h-screen text-egray">
        <Header />
        <div className="grow">
          <div
            className={`${
              showResult ? "h-full opacity-100" : "h-0 opacity-0"
            } text-center transition-opacity duration-1000 ease-in flex flex-col justify-center`}
          >
            <p>today you must play </p>
            <p>
              <WordleLink wordle={todaysResult?.wordleSelected} />
            </p>
          </div>
          <div
            className={`${todaysResult ? "opacity-0" : "opacity-100"} ${
              showResult ? "h-0" : "h-full"
            } text-center transition-opacity duration-1000 ease-in flex flex-col justify-center`}
            onTransitionEnd={() => {
              setShowResult(true);
            }}
          >
            <p> press any key </p>
            <p>that speaks to you</p>
          </div>
          {/* <div
            className={`${showWarning ? "opacity-100" : "opacity-0"} ${
              showResult ? "h-0" : "h-full"
            } text-center transition-opacity duration-1000 ease-in flex flex-col justify-center`}
            onTransitionEnd={() => {
              setShowResult(true);
            }}
          >
            <p> come back tomorrow </p>
          </div> */}
        </div>
        <Keyboard
          pressedKey={todaysResult?.keyPressed}
          setPressedKey={saveRandomResult}
        />
      </div>
    </div>
  );
};

export default WhichWordle;
