import React from "react";

class BackgroundCanvas extends React.Component<{}, {}> {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  selfRef: React.RefObject<HTMLDivElement>;

  constructor(props = {}) {
    super(props);
    this.canvasRef = React.createRef();
    this.selfRef = React.createRef();
  }

  componentDidMount() {
    const canvas = this.canvasRef.current;
    const mainDiv = this.selfRef.current;
    if (!canvas || !mainDiv) {
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }
    canvas.height = mainDiv.offsetHeight;
    canvas.width = mainDiv.offsetWidth;
    console.log(mainDiv.offsetHeight)
    console.log(mainDiv.offsetWidth)
 
    ctx.fillStyle = "#000000";
    ctx.fillRect(0, 0, canvas.width, canvas.height); 
  }

  drawWithPhone(e: React.TouchEvent<HTMLCanvasElement>) {
    e.preventDefault();
    let touchEvent = e.touches[0];
    this.draw(touchEvent.clientX, touchEvent.clientY)
  }

  drawWithMouse(e: React.MouseEvent<HTMLCanvasElement>) {
    e.preventDefault();
    let touchEvent = e;
    this.draw(touchEvent.clientX, touchEvent.clientY)
  }

  draw(xTouch: number, yTouch: number) {
    const canvas = this.canvasRef.current;
    const mainDiv = this.selfRef.current;
    if (!canvas || !mainDiv) {
      return;
    }

    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    ctx.globalCompositeOperation = 'destination-out'
    ctx.beginPath();
    ctx.fillStyle = "#ffffff";

    let mainDivLeft = mainDiv.getBoundingClientRect().left;
    let mainDivTop = mainDiv.getBoundingClientRect().top;

    let x = xTouch - mainDivLeft;
    let y = yTouch - mainDivTop;
    console.log(`cursor is at (${x},${y}) while main div is at (${mainDivLeft}, ${mainDivTop})`)
    ctx.arc(x, y, 40, 0, 2 * Math.PI, false);
    ctx.fill();

    this.forceUpdate();

  }

  render() {
    return (
      <div ref={this.selfRef}>
        {this.props.children}
        <canvas
          ref={this.canvasRef}
          onTouchMove={this.drawWithPhone.bind(this)}
          onMouseMove={this.drawWithMouse.bind(this)}
          onTouchStart={this.drawWithPhone.bind(this)}
          className="background-canvas"
        />
      </div>
    );
  }
}

export default BackgroundCanvas;
