import * as React from "react";

const Letter: React.FC<{
  l: string;
  pressedKey: string | undefined;
  setPressedKey: (key: string) => void;
}> = ({ l, pressedKey, setPressedKey }) => {
  return (
    <div
      className={`${
        pressedKey === l ? "bg-epink" : "bg-egreen"
      } rounded-md flex-1 flex items-center justify-center h-12 text-egray uppercase cursor-pointer`}
      onClick={() => {
        setPressedKey(l);
      }}
    >
      {l}
    </div>
  );
};

const Spacer = () => {
  return <div className="grow-[0.5] shrink basis-0" />;
};

const Row: React.FC<{
  letters: string[];
  pressedKey: string | undefined;
  setPressedKey: (key: string) => void;
}> = ({ letters, pressedKey, setPressedKey }) => {
  return (
    <div className="flex space-x-1 sm:space-x-2 w-full">
      {letters.map((l, idx) =>
        l === "spacer" ? (
          <Spacer key={idx} />
        ) : (
          <Letter
            l={l}
            key={idx}
            pressedKey={pressedKey}
            setPressedKey={setPressedKey}
          />
        )
      )}
    </div>
  );
};

const Keyboard: React.FC<{
  pressedKey: string | undefined;
  setPressedKey: (key: string) => void;
}> = ({ pressedKey, setPressedKey }) => {
  return (
    <div className="flex flex-col items-center space-y-1 sm:space-y-2 w-full p-2">
      <Row
        letters={["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"]}
        pressedKey={pressedKey}
        setPressedKey={setPressedKey}
      />
      <Row
        letters={[
          "spacer",
          "a",
          "s",
          "d",
          "f",
          "g",
          "h",
          "j",
          "k",
          "l",
          "spacer",
        ]}
        pressedKey={pressedKey}
        setPressedKey={setPressedKey}
      />
      <Row
        letters={[
          "spacer",
          "spacer",
          "spacer",
          "z",
          "x",
          "c",
          "v",
          "b",
          "n",
          "m",
          "spacer",
          "spacer",
          "spacer",
        ]}
        pressedKey={pressedKey}
        setPressedKey={setPressedKey}
      />
    </div>
  );
};

export default Keyboard;
